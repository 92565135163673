/**
 *
 *  Defective Product
 *  Cancel Request
 *  Order status
 *  Exchange / Return Request
 *  Product / Sizeing Inquiry
 *  Report Bugs
 *
 */

export const InquiryTypes = [
  { label: 'Defective Product', value: 'defective_product' },
  { label: 'Cancel Request', value: 'cancel_request' },
  { label: 'Order status', value: 'order_status' },
  { label: 'Exchange / Return Request', value: 'exchange_return_request' },
  { label: 'Product / Sizeing Inquiry', value: 'product_sizeing_inquiry' },
  { label: 'Report Bugs', value: 'report_bugs' },
];

export const ApplicationTypes = [
  { label: 'Exchange Request', value: 'exchange_request' },
  { label: 'Return / Refund Request', value: 'return_refund_request' },
  { label: 'Other Request', value: 'other_request' },
];

export const ReasonTypes = [
  { label: 'Damaged Item', value: 'damaged_item' },
  { label: 'Not as Described', value: 'not_as_described' },
  { label: 'Incorrect Size', value: 'incorrect_size' },
  { label: 'Incorrect Color', value: 'incorrect_color' },
  { label: 'Wrong Item Received', value: 'wrong_item_received' },
  { label: 'Missing Accessories', value: 'missing_accessories' },
  { label: 'Other', value: 'other' },
];