import {Box, AlertDialog, Flex, Heading, Text, Grid, Button, Link, Em} from '@radix-ui/themes';
import React, {useEffect, useState} from 'react';
import * as Form from '@radix-ui/react-form';
import { ApplicationTypes, ReasonTypes } from '@/data/inquiryTypes';
import { FormText } from '@/components/FormText';
import { FormField } from '@/components/FormField';
import { FormSelect } from '@/components/FormSelect';
import { FormTextArea } from '@/components/FormTextArea';
import { PaperPlaneIcon } from '@radix-ui/react-icons';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { submitFeedback } from '@/rest/api/feedback';
import {GotOrderNumTooltip } from './GotOrderNumTooltip';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
// import {useSessionStorage} from 'react-use';
import useSessionStorage from '@/utils/hooks/useSessionStorage';

const schema = z.object({
  firstName: z
    .string({
      required_error: 'Please enter your first name',
    })
    .trim()
    .min(1, 'Please enter your first name'),
  lastName: z.
      string({
        required_error: 'Please enter your last name',
      })
      .trim()
      .min(1, 'Please enter your last name'),
  email: z.string().email({
    message: 'Please enter a valid email address',
  }),
  // phone: z.string().regex(/^\+(?:[0-9]){6,14}[0-9]$/).optional(),
  // phone: z.number().optional(),
  phone: z
    .string()
    .optional()
    .refine(
      (val) => {
        if (val === undefined || val === '') return true;
        const normalizedVal = val.startsWith('+') ? val.slice(1) : val;
        return /^[0-9]{6,14}[0-9]$/.test(normalizedVal);
      },
      {
        message: 'Please enter a valid phone number',
      },
    ),
    // orderNum: z.number({
    //   invalid_type_error: 'Please enter a valid order number',
    // }).optional(),
    // orderNum: z.string().refine(v => { let n = Number(v); return !isNaN(n) && v?.length > 0}, {message: "Invalid number"}),
    // orderNum: z.preprocess((x) => (x ? x : undefined), z.coerce.number().int().min(1).max(31).optional()),
    // orderNum: z.coerce.number({
    //   message: 'Please enter a valid order number',
    // }).nullish().transform((val)=> ''+val),
  orderNum: z.string().optional(),
  inquiryType: z.string({
      required_error: 'Please select an application type',
    }).trim().min(1, 'Please select an application type'),
  inquiryReason: z.string({
      required_error: 'Please select a reason',
    }).trim().min(1, 'Please select a reason'),
  content: z.string().optional(),
  target: z.string().optional(),
});

type FormData = z.infer<typeof schema>;

const CustomerComplaintForm = () => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
        getValues, setValue,
  } = useForm<FormData>({
    resolver: zodResolver(schema),
  });
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // access query parameters
  const queryParamsTarget = queryParams.get("target");
  const [currentData, setCurrentData, removeCurrentData] = useSessionStorage<Record<string, string>|null>('currentData', null);
  const [_, setSubmitResult, removeSubmitResult] = useSessionStorage<API.SubmitFeedBackResponse|null>('complaint-submit-result', null);


  const navigate = useNavigate();


  // const [isSuccess, setIsSuccess] = React.useState(false);

  const resetDefaultValues = () => {
    console.log("resetDefaultValues");
    removeCurrentData();
    reset({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      orderNum: '',
      inquiryType: '',
      inquiryReason: '',
      content: '',
        target: '',
    });
  }

  useEffect(() => {
    console.log("reload page");
    console.log("query target", queryParamsTarget);
    console.log("currentData", currentData);
    removeSubmitResult();
  },[]);

  useEffect(() => {
    const savedFormData = currentData;
    console.log("useEffect update set value", savedFormData);
    if (savedFormData) {
      // const formData = JSON.parse(savedFormData);
        const formData = savedFormData;
      Object.keys(formData).forEach((key) => {
        // @ts-ignore
        setValue(key, formData[key]);
      });
    }
  }, [setValue])

  const handleGetOrderNumClick = () => {
    // console.log("handleGetOrderNumClick");
    const formData = getValues();
    console.log("handleGetOrderNumClick", formData);
    // sessionStorage.setItem(currentDataKey, JSON.stringify(currentData));
    setCurrentData(formData);
    navigate('/fetchOrderNum');
  };

  const onSubmit = async (data: FormData) => {

  // console.log("onSubmit", data);
  try {

    const params: API.SubmitFeedBackParams = {
      ...data,
      target: queryParamsTarget ?? undefined,
    }

    const response = await submitFeedback(params);
    if (response?.data.code === 200) {
        console.log('Feedback submitted successfully');
      resetDefaultValues();
      setSubmitResult(response.data.data);
        navigate('/submitSuccess');
    }
  } catch (error) {
    console.error('Failed to submit feedback', error);
  }
    // resetDefaultValues();
    // setIsSuccess(true);
  };

  return (
    <>
      {/*<Box style={{ whiteSpace: 'nowrap' }}>*/}
        <Flex width="100%" minHeight="100vh" justify={'center'}>
          <Flex py={{ initial: '7', xs: '9', sm: '100px' }}>
     
            <Box px={{ initial: '4' }}>
              <Grid columns="1" gap="3">
                <Heading
                  size={{
                    initial: '7',
                    md: '8',
                  }}
                >
                  Help Center
                </Heading>
                <Text wrap="pretty">
                Need Help? You're in the right place
                </Text>
                <Text wrap="pretty" mt={'6'}>
                  Feel free to contact us if you have any questions about products, your order or any other inquiries
                </Text>
                <Text wrap="pretty">
                Please allow up to 6-12 hours for a response to your inquiry. Emails sent during non-business hours will be responded to on the next business day.
                </Text>
              </Grid>

              <Box mt="9">
                <Flex direction={'column'} gap="2">
                  <Form.Root onSubmit={handleSubmit(onSubmit)}>
                    <Grid columns="2" gap="2">
                      <FormText
                          name="firstName"
                          register={register}
                          label="First Name"
                          validations={
                            errors.firstName?.message
                                ? [{ label: errors.firstName.message as string }]
                                : []
                          }
                          placeholder="Enter your full name"
                      />
                      <FormText
                          name="lastName"
                          register={register}
                          label="Last Name"
                          validations={
                            errors.lastName?.message
                                ? [{ label: errors.lastName.message as string }]
                                : []
                          }
                          placeholder="Enter your full name"
                      />
                    </Grid>
                    <FormText
                      name="email"
                      label="Email"
                      type="text"
                      register={register}
                      placeholder="Enter your email"
                      validations={
                        errors.email?.message ? [{ label: errors.email.message as string }] : []
                      }
                    />
                    <FormText
                      name="phone"
                      label="Telephone"
                      type="text"
                      placeholder="Enter your phone number"
                      required={false}
                      register={register}
                      validations={
                        errors.phone?.message ? [{ label: errors.phone.message as string }] : []
                      }
                    />
                    <FormText
                      name="orderNum"
                      label="Order Number"
                      type="text"
                      placeholder="Enter your order number"
                      // tooltip={<GotOrderNumTooltip />}
                      tooltip={<Link onClick={handleGetOrderNumClick} size={{
                        initial: '1',
                        sm: '2',
                      }}>

                          How to Got Order Number?

                      </Link>}
                      required={false}
                      register={register}
                      validations={
                        errors.orderNum?.message ? [{ label: errors.orderNum.message as string }] : []
                      }
                    />

                    <FormSelect
                      control={control}
                      name="inquiryType"
                      label="Inquiry Type"
                      // register={register}
                      validations={
                        errors.inquiryType?.message
                          ? [{ label: errors.inquiryType.message as string }]
                          : []
                      }
                      options={ApplicationTypes}
                      placeholder="Select Inquiry Type"
                    />

                    <FormSelect
                      control={control}
                      name="inquiryReason"
                      label="Reason"
                      // register={register}
                      validations={
                        errors.inquiryReason?.message
                          ? [{ label: errors.inquiryReason.message as string }]
                          : []
                      }
                      options={ReasonTypes}
                      placeholder="Select Reason"
                    />

                    <FormTextArea
                      name="content"
                      label="Tell US More Details"
                      placeholder="Enter more details"
                      register={register}
                      required={false}
                      validations={
                        errors.content?.message ? [{ label: errors.content.message as string }] : []
                      }
                    />

                    <Form.Submit asChild>
                      <Flex justify={'center'}>
                        <Button size={'4'} style={{ width: '80%' }}>
                        Submit
                          <PaperPlaneIcon />
                        </Button>
                      </Flex>
                    </Form.Submit>
                  </Form.Root>
                </Flex>
              </Box>
            </Box>
          </Flex>

          {/*<AlertDialog.Root open={isSuccess} onOpenChange={setIsSuccess}>*/}
          {/*  /!*<AlertDialog.Trigger>*!/*/}
          {/*  /!*  <Button size="4">Open</Button>*!/*/}
          {/*  /!*</AlertDialog.Trigger>*!/*/}
          {/*  <AlertDialog.Content size={{*/}
          {/*    initial: '1',*/}
          {/*    sm: '2',*/}
          {/*    md: '4'*/}
          {/*  }}*/}
          {/*                       style={{ padding: '20px' }}*/}
          {/*                       // style={{ width: 'calc(450px * var(--scaling))' }}*/}
          {/*  >*/}
          {/*    <AlertDialog.Title size="6">  Submission Successful</AlertDialog.Title>*/}
          {/*    <AlertDialog.Description size="3" mb="5">*/}
          {/*      Your feedback has been submitted successfully.*/}
          {/*    </AlertDialog.Description>*/}

          {/*    <Flex gap="3" mt="5" justify="end">*/}

          {/*      <AlertDialog.Action>*/}
          {/*        <Button size="3"  >*/}
          {/*          OK*/}
          {/*        </Button>*/}
          {/*      </AlertDialog.Action>*/}
          {/*    </Flex>*/}
          {/*  </AlertDialog.Content>*/}
          {/*</AlertDialog.Root>*/}
        </Flex>
      {/*</Box>*/}
    </>
  );
};
export default CustomerComplaintForm;
